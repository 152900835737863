import jwtDecode from "jwt-decode";

const token = localStorage.getItem("id_token");
const refreshToken = localStorage.getItem("refresh_token");
const email = localStorage.getItem("email");
const userId = localStorage.getItem("user_id");
const userRole = localStorage.getItem("user_role");
const exp = token && jwtDecode(token).exp;

const auth = {
  isAuthenticated: token && Date.now().valueOf() / 1000 <= exp,
  token: token,
  refreshToken: refreshToken,
  email: email,
  userId: userId,
  userRole: userRole,

  authenticate({ idToken, refreshToken }, cb) {
    this.isAuthenticated = true;
    this.token = idToken;
    this.refreshToken = refreshToken;
    this.email = jwtDecode(idToken).email;
    this.userRole = jwtDecode(idToken)["cognito:groups"] || "user";
    localStorage.setItem("id_token", this.token);
    localStorage.setItem("refresh_token", this.refreshToken);
    localStorage.setItem("email", this.email);
    localStorage.setItem("user_role", this.userRole);
    cb();
  },

  storeRefreshedToken(refreshedToken) {
    this.token = refreshedToken;
    this.userRole = jwtDecode(refreshedToken)["cognito:groups"] || "user";
    localStorage.setItem("id_token", this.token);
    localStorage.setItem("user_role", this.userRole);
  },

  storeEmail(email) {
    this.email = email;
    localStorage.setItem("email", this.email);
  },

  storeUserId(userId) {
    this.userId = userId;
    localStorage.setItem("user_id", this.userId);
  },

  signout(cb) {
    this.isAuthenticated = false;
    this.token = null;
    this.refreshToken = null;
    this.userId = null;
    this.userRole = null;
    localStorage.removeItem("id_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("user_id");
    localStorage.removeItem("user_role");
    cb();
  },
};

export default auth;
