import { grayColor } from "assets/jss/index";

const loadingStyle = {
  loadingColor: {
    color: grayColor,
  },
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "10%",
  },
};

export default loadingStyle;
