import React from "react";

import CircularProgress from "@material-ui/core/CircularProgress";
import withStyles from "@material-ui/core/styles/withStyles";

import loadingStyle from "./loadingStyle";

const Loading = (props) => {
  const { classes } = props;
  return (
    <div className={classes.container}>
      <CircularProgress size={80} className={classes.loadingColor} />
    </div>
  );
};

export default withStyles(loadingStyle)(Loading);
