import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Router, Route, Switch } from "react-router-dom";

import { PrivateRoute } from "components/CustomRoutes";
import Login from "containers/Login/Loadable";
import RedirectLogin from "containers/RedirectLogin/Loadable";
import SignUp from "containers/SignUp/Loadable";
import GuestSignUp from "containers/GuestSignUp/Loadable";
import ForgotPassword from "containers/ForgotPassword/Loadable";
import ConfirmForgotPassword from "containers/ConfirmForgotPassword/Loadable";
import ResendVerifyLink from "containers/ResendVerifyLink/Loadable";

import "assets/scss/index.css";
import "assets/scss/sweet-alert.scss";

import configureStore from "./store";
import history from "./history";
import indexRoutes from "./routes";

const store = configureStore();
export default store;

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/redirect-login" component={RedirectLogin} />
        <Route path="/sign-up" component={SignUp} />
        <Route path="/sign-up-guest" component={GuestSignUp} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/redirect-forgot-password" component={ForgotPassword} />
        <Route
          path="/confirm-forgot-password"
          component={ConfirmForgotPassword}
        />
        <Route
          path="/redirect-confirm-forgot-password"
          component={ConfirmForgotPassword}
        />
        <Route path="/resend-verify-link" component={ResendVerifyLink} />
        {indexRoutes.map((prop, key) => (
          <PrivateRoute path={prop.path} component={prop.component} key={key} />
        ))}
      </Switch>
    </Router>
  </Provider>,
  document.getElementById("root")
);
